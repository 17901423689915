<template>
  <!-- <div class="container-fluid"> -->
  <div class="row row-cols-1">
    <loading :active.sync="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage">
      <template v-slot:before><span class="loadingText">請稍後...</span> </template></loading
    >
    <div class="col-12 mt-5">
      <div class="row row-cols-1">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <div class="alert alert-danger" role="alert">網站服務調整 (2024/11/1起) <a href="https://www.facebook.com/just.bk.me/posts/868366018794844" target="_blank">見詳細公告</a></div>
        </div>
        <div class="col-md-4"></div>
      </div>
      <div class="row row-cols-1 mt-2">
        <main class="form-signin w-auto m-auto">
          <div class="alert alert-warning m-3" role="alert">若註冊時不慎將帳號輸入錯誤導致無法登入，還請向本站聯繫</div>

          <div class="alert alert-danger" role="alert" v-if="alerts.length > 0">
            <!-- {{ alert }} -->
            <ul>
              <li v-for="(alert, key) in alerts" :key="key">{{ alert }}</li>
            </ul>
          </div>
          <div class="shadow p-3 mb-5 bg-body rounded g-light bg-gradient ms-3 me-3">
            <form @submit.prevent="handlerUserLogin">
              <!-- <img class="mb-4" src="/docs/5.3/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57" /> -->
              <h3 class="text-center"><i class="bi bi-door-closed"></i> 登入</h3>
              <p class="text-right">
                還沒有帳號嗎？
                <router-link :to="{ name: 'newUser' }" class="btn btn-link">來註冊一個吧！</router-link>
              </p>
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="floatingInput" v-model="form.email" placeholder="name@example.com" />
                <label for="floatingInput">E-mail</label>
              </div>
              <div class="form-floating mb-3">
                <input type="password" class="form-control" id="floatingPassword" v-model="form.password" placeholder="Password" />
                <label for="floatingPassword">密碼</label>
              </div>
              <div class="form-check text-start my-3">
                <div class="row">
                  <div class="col-6">
                    <input class="form-check-input" type="checkbox" v-model="checked" @click="rememberMe($event)" id="flexCheckDefault" />
                    <label class="form-check-label" for="flexCheckDefault"> 記住帳號 </label>
                  </div>
                  <div class="col-6"><button type="button" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#resetPwdModal">忘記密碼</button></div>
                </div>
              </div>
              <div class="d-flex justify-content-start">
                <button class="btn btn-primary py-2 btn-lg" type="submit">登入</button>
              </div>
              <p class="mt-5 mb-1 text-body-secondary text-end">&copy; Just.崩潰 2025</p>
            </form>
          </div>
        </main>
      </div>
    </div>
    <div class="modal fade" id="resetPwdModal" tabindex="-1" aria-labelledby="resetPwdModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="resetPwdModalLabel">忘記密碼</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="handlerPasswordReset" class="row row-cols-lg-auto g-3 align-items-center">
              <div class="col-12 mb-3">
                <label for="recipient-name" class="col-form-label">請填寫當時註冊時的 E-mail</label>
                <input type="text" class="form-control" id="email" v-model="resetpwd_form.email" required />
                <div id="emailHelpBlock" class="form-text">如 E-mail 忘記，請直接和本站聯繫</div>
              </div>
              <div class="col-12 mb-1">
                <button type="submit" class="btn btn-primary">重設申請</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- </div> -->
</template>
<script>
  import AES from "crypto-js/aes"
  import Joi from "joi"
  // import encUtf8 from 'crypto-js/enc-utf8';
  import axios from "axios"
  // import moment from "moment"
  // import jsonwebtoken from "jsonwebtoken"
  import Cookies from "js-cookie"
  import Loading from "vue-loading-overlay"
  import "vue-loading-overlay/dist/vue-loading.css"

  export default {
    name: "VueLogin",
    components: {
      // LineLogin: () => import("./LineLogin.vue"),
      //   BootstrapTable: BootstrapTable,
      // LineLogin,
      Loading,
    },
    mounted() {
      if (Cookies.get("login_id")) {
        this.form.email = Cookies.get("login_id")
      }

      if (Cookies.get("remember_me")) {
        this.checked = Cookies.get("remember_me")
      }

      const resetPwdModal = document.getElementById("resetPwdModal")
      if (resetPwdModal) {
        resetPwdModal.addEventListener("show.bs.modal", async () => {})
      }
    },
    data: function () {
      return {
        resetpwd_form: {
          email: "",
        },
        form: {
          email: "",
          // name: "",
          password: "",
          passwordVerify: "",
        },
        alerts: [],
        checked: false,
        isLoading: false,
        fullPage: true,
      }
    },
    methods: {
      onCancel() {},
      rememberMe(e) {
        Cookies.set("login_id", this.form.email, { secure: true })
        Cookies.set("remember_me", !this.checked, { secure: true })
        if (!e.target.checked) {
          Cookies.remove("login_id")
          Cookies.remove("remember_me")
        }
      },
      async handlerPasswordReset(e) {
        e.preventDefault()
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 3000)

        const schema = Joi.object({
          email: Joi.string()
            .email({ minDomainSegments: 2, tlds: { allow: false } })
            .error((errors) => {
              // console.log(errors)
              errors.forEach((err) => {
                switch (err.code) {
                  case "string.empty":
                    err.message = "E-mail 不能為空！"
                    break
                  case "string.email":
                    err.message = `所輸入的 E-mail 不符合格式要求`
                    break
                  default:
                    break
                }
              })
              return errors
            }),
        })

        try {
          await schema.validateAsync({ email: this.resetpwd_form.email })
          const key = process.env.VUE_AES_KEY // change to your key
          // const iv = "sinasinasisinaaa" // change to your iv
          // const now = moment()
          const json_d = {
            email: this.resetpwd_form.email,
            v: AES.encrypt(this.resetpwd_form.email, key).toString(),
            // from: "portal",
          }

          const me = this

          axios({
            method: "POST",
            url: `${me.$apiUrl2}resetPwdByEmail`,
            data: json_d,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
          }).then(function (response) {
            const res = response.data
            // const res = d.data
            if (res.status === 201) {
              if (res.statusText === "Created" || res.statusText === "Update") {
                me.$alert("重設通知信已寄出，還煩請檢查當時所登錄的 E-mail 信箱", "系統訊息", "success").then(() => {
                  window.location.href = `/Login`
                })
              }
            }
          })
        } catch (err) {
          // this.alerts.push(err)
        }
      },
      async handlerUserLogin(e) {
        e.preventDefault()
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 5000)

        // const {  verify } = jsonwebtoken
        this.alerts = []

        const schema = Joi.object({
          password: Joi.string()
            // .pattern(new RegExp("^[a-zA-Z0-9]{3,30}$"))
            .error((errors) => {
              // console.log(errors)
              errors.forEach((err) => {
                switch (err.code) {
                  case "string.empty":
                    err.message = "密碼不能為空！"
                    break
                  // case "string.pattern.base":
                  //   err.message = `所輸入的密碼必須符合設定原則(僅限英數且長度介於 3~30 字元間)`
                  //   break
                  default:
                    break
                }
              })
              return errors
            }),
          email: Joi.string()
            .email({ minDomainSegments: 2, tlds: { allow: false } })
            .error((errors) => {
              // console.log(errors)
              errors.forEach((err) => {
                switch (err.code) {
                  case "string.empty":
                    err.message = "E-mail 不能為空！"
                    break
                  case "string.email":
                    err.message = `所輸入的 E-mail 不符合格式要求`
                    break
                  default:
                    break
                }
              })
              return errors
            }),
        })

        try {
          await schema.validateAsync({ email: this.form.email, password: this.form.password })
          const key = process.env.VUE_AES_KEY // change to your key
          // const iv = "sinasinasisinaaa" // change to your iv

          const json_d = {
            email: this.form.email,
            password: AES.encrypt(this.form.password, key).toString(),
            from: "portal",
          }

          const me = this

          axios({
            method: "POST",
            url: `${me.$apiUrl2}userLogin`,
            data: json_d,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
          }).then(function (response) {
            const res = response.data
            // const res = d.data

            if (res.status === 503) {
              if (res.errorCode === "Error") {
                // me.$alert("登入失敗", "系統訊息", "error").then(() => {
                // window.location.href = `/`
                // })
                if (res.data.login_status === false) {
                  me.$alert("登入失敗，請檢查帳號及密碼是否正確", "系統訊息", "error").then(() => {})
                  return
                }
                me.$alert("登入失敗，請檢查帳號否正確", "系統訊息", "error").then(() => {})
                return
              }
            }
            if (res.status === 201) {
              if (res.data.login_status === true) {
                if (res.data.members_status.is_used === false) {
                  me.$alert("登入失敗，請聯繫站長", "系統訊息", "error").then(() => {
                    window.location.href = `/`
                  })
                  return
                }

                Cookies.set("login_id", me.form.email, { secure: true })
                Cookies.set("login_token", res.data.login_token, { secure: true })
                // const user_uuid = res.data.id

                if (res.data.members_status.is_email_confirm === false) {
                  // me.$fire({
                  //   title: "系統訊息",
                  //   text: "你好！該 E-mail 目前尚未完成 E-mail 驗證，由於 E-mail 驗證在目前並非必要動作，若有完成驗證可同時提高本站其他服務可用額度，你是否要繼續完成 E-mail 驗證？",
                  //   showDenyButton: true,
                  //   showCancelButton: false,
                  //   confirmButtonText: "開始驗證",
                  //   denyButtonText: "下次再說",
                  // }).then((result) => {
                  //   if (result.isConfirmed) {
                  //     const json_d = {
                  //       uuid: user_uuid,
                  //       from: "portal",
                  //     }
                  //     axios({
                  //       method: "POST",
                  //       url: `${me.$apiUrl2}sendUserAccountVaildCode`,
                  //       data: json_d,
                  //       headers: {
                  //         Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                  //         "Content-Type": "application/json",
                  //       },
                  //     }).then(function (response) {
                  //       const res = response.data
                  //       if (res.status === 201) {
                  //         if (res.statusText === "Created" || res.statusText === "Update") {
                  //           me.$alert("驗證碼已寄出，還煩請檢查所登錄的 E-mail 信箱", "系統訊息", "success").then(() => {
                  //             window.location.href = `/Login`
                  //           })
                  //         }
                  //       }
                  //     })
                  //   } else if (result.isDenied) {
                  //     // Cookies.set("login_id", me.form.email, { secure: true })
                  //     window.location.href = `/Subscribe?id=${user_uuid}&op=notify_manage`
                  //   }
                  // })
                  if (sessionStorage) {
                    if (sessionStorage.getItem("page")) {
                      const airline = sessionStorage.getItem("airline")
                      const tag = sessionStorage.getItem("tag")
                      const page = sessionStorage.getItem("page")
                      if (sessionStorage.getItem("ref") && sessionStorage.getItem("ref") === true) {
                        const page = sessionStorage.getItem("page")
                        // const ref = sessionStorage.getItem("ref")

                        window.location.href = `/${page}?ref=${airline}-${tag}`
                      } else {
                        if (typeof airline !== "undefined" && typeof tag !== "undefined") {
                          window.location.href = `/${page}?ref=${airline}-${tag}`
                        } else {
                          window.location.href = `/${page}`
                        }
                      }
                    } else {
                      // window.location.href = `/Subscribe?id=${user_uuid}&op=notify_manage`
                      window.location.href = `/`
                    }
                  }

                  if (Cookies) {
                    if (Cookies.get("page")) {
                      const airline = Cookies.get("airline")
                      const tag = Cookies.get("tag")
                      const page = Cookies.get("page")
                      if (Cookies.get("ref") && Cookies.get("ref") === true) {
                        const page = Cookies.get("page")
                        // const ref = sessionStorage.getItem("ref")

                        window.location.href = `/${page}?ref=${airline}-${tag}`
                      } else {
                        if (typeof airline !== "undefined" && typeof tag !== "undefined") {
                          window.location.href = `/${page}?ref=${airline}-${tag}`
                        } else {
                          window.location.href = `/${page}`
                        }
                      }
                    } else {
                      // window.location.href = `/Subscribe?id=${user_uuid}&op=notify_manage`
                      window.location.href = `/`
                    }
                  }

                  // return
                } else {
                  if (sessionStorage) {
                    if (sessionStorage.getItem("page")) {
                      const airline = sessionStorage.getItem("airline")
                      const tag = sessionStorage.getItem("tag")
                      const page = sessionStorage.getItem("page")
                      if (sessionStorage.getItem("ref") && sessionStorage.getItem("ref") === true) {
                        const page = sessionStorage.getItem("page")
                        // const ref = sessionStorage.getItem("ref")

                        window.location.href = `/${page}?ref=${airline}-${tag}`
                      } else {
                        // if (me.$route.query.url) {
                        //   const url = me.$route.query.url
                        //   window.location.href = `${url}`
                        // }

                        if (typeof airline !== "undefined" && typeof tag !== "undefined") {
                          window.location.href = `/${page}?ref=${airline}-${tag}`
                        } else {
                          window.location.href = `/${page}`
                        }
                      }
                    } else {
                      // Cookies.set("login_id", me.form.email, { secure: true })
                      // window.location.href = `/Subscribe?id=${user_uuid}&op=notify_manage`
                      window.location.href = `/`
                    }
                  }

                  if (Cookies) {
                    if (Cookies.get("page")) {
                      const airline = Cookies.get("airline")
                      const tag = Cookies.get("tag")
                      const page = Cookies.get("page")
                      if (Cookies.get("ref") && Cookies.get("ref") === true) {
                        const page = Cookies.get("page")
                        // const ref = sessionStorage.getItem("ref")

                        window.location.href = `/${page}?ref=${airline}-${tag}`
                      } else {
                        if (typeof airline !== "undefined" && typeof tag !== "undefined") {
                          window.location.href = `/${page}?ref=${airline}-${tag}`
                        } else {
                          window.location.href = `/${page}`
                        }
                      }
                    } else {
                      // window.location.href = `/Subscribe?id=${user_uuid}&op=notify_manage`
                      window.location.href = `/`
                    }
                  }
                }
              }
            }

            // if (res.data.login_status === false) {
            //   me.$alert("登入失敗，請檢查所輸入的E-mail及密碼是否正確", "系統訊息", "error").then(() => {})
            // }
          })
        } catch (err) {
          this.alerts.push(err)
        }
      },
    },
  }
</script>
