var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col m-6"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row row-cols-1 row-cols-md-12 g-4"},[_vm._m(0),_c('h4',[_c('p',{staticClass:"mt-0"},[_vm._v(_vm._s(_vm.main_title)),_c('small',[_vm._v(" (逐步更新，非完整)")])])]),_c('small',{staticClass:"mt-0"},[_c('ul',[_c('li',[_vm._v("溫馨提醒：如透過行動裝置進行操作，由於本站對於in-app瀏覽器（如Facebook 或 LINE 內建）支援程度不佳，如遇到資料無法呈現，可建議使用 Google Chrome、Safari 等系統原生或第三方瀏覽器")]),_c('li',[_vm._v("若使用行動裝置，建議可切換成橫向檢視來得到較佳瀏覽體驗")]),_c('li',[_vm._v(_vm._s(_vm.help_title)+"況")]),_c('li',[_vm._v("僅供參考，由於資料本身具有時效性，建議要搭配參考資料取得時間 (UTC+8)")]),_c('li',[_vm._v(_vm._s(_vm.sub_title))])])]),_c('vue-good-table',{attrs:{"line-numbers":false,"search-options":{
          enabled: false,
        },"pagination-options":{
          enabled: true,
          mode: 'pages',
        },"sort-options":{
          enabled: true,
          multipleColumns: true,
          initialSortBy: [
            // { field: 'departureDate', type: 'asc' },
            { field: 'fareAmount', type: 'asc' },
          ],
        },"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col mt-5"},[_c('div',{staticClass:"alert alert-danger mt-4",attrs:{"role":"alert"}},[_vm._v("網站服務調整 (2024/11/1起) "),_c('a',{attrs:{"href":"https://www.facebook.com/just.bk.me/posts/868366018794844","target":"_blank"}},[_vm._v("見詳細公告")])])])
}]

export { render, staticRenderFns }