<template>
  <div class="col m-6">
    <div class="container-fluid">
      <div class="row row-cols-1 row-cols-md-12 g-4">
        <div class="col mt-5">
          <div class="alert alert-danger mt-4" role="alert">網站服務調整 (2024/11/1起) <a href="https://www.facebook.com/just.bk.me/posts/868366018794844" target="_blank">見詳細公告</a></div>
        </div>
        <h4>
          <!-- <p class="mt-5">台灣虎航 24夏季第一波開賣各航點價格一覽表<small> (逐步更新，非完整)</small></p> -->
          <p class="mt-0">{{ main_title }}<small> (逐步更新，非完整)</small></p>
        </h4>
        <small class="mt-0">
          <!-- <h2><p>台灣虎航 24夏季第一波開賣各航點價格一覽表</p></h2> -->
          <ul>
            <li>溫馨提醒：如透過行動裝置進行操作，由於本站對於in-app瀏覽器（如Facebook 或 LINE 內建）支援程度不佳，如遇到資料無法呈現，可建議使用 Google Chrome、Safari 等系統原生或第三方瀏覽器</li>
            <!-- <li v-if="help_title !== ''">
              <span class="fs-6 text-danger">{{ help_title }}</span
              >，若沒有可以點擊一旁 <router-link to="/NewUser" class="btn btn-outline-primary"> 註冊 </router-link>
            </li> -->
            <li>若使用行動裝置，建議可切換成橫向檢視來得到較佳瀏覽體驗</li>
            <li>所提供的票價資訊均是建立在「直飛，日本出發的 <span class="fs-4 text-danger"> 單程 </span>且大人人數是 1 位」的情況</li>
            <li>僅供參考，由於資料本身具有時效性，建議要搭配參考資料取得時間 (UTC+8)</li>
            <li v-html="sub_title"></li>
          </ul>
        </small>
        <vue-good-table
          :line-numbers="false"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
          }"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: [
              { field: 'departureDate', type: 'asc' },
              { field: 'fareAmount', type: 'asc' },
            ],
          }"
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table striped" />
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import Cookies from "js-cookie"
  import GlobalVar from "@/mixins/global_var.js"
  export default {
    name: "VueLowFare",
    // components: { Table },
    mounted() {
      // if (Cookies.get("login_token")) {
      const login_token = Cookies.get("login_token")
      this.checkLoginTokeVaild(login_token)

      let redirect_url = "/Login"

      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        const airline = ref.split("-")[0]
        const tag = ref.split("-")[1]
        redirect_url = `/Login?url=/LowfareOTA?ref=${airline}-${tag}`
      }

      // this.flag = sessionStorage.getItem("ref")
      // if (!sessionStorage.getItem("is_email_confirm")) {
      // if (sessionStorage.getItem("is_email_confirm")) {
      // this.columns.splice(4, 0, {
      //   label: "張數",
      //   width: "100px",
      //   field: "fareAvailable",
      //   filterOptions: {
      //     styleClass: "class1", // class to be added to the parent th element
      //     enabled: true, // enable filter for this column
      //     placeholder: "", // placeholder for filter input
      //     filterValue: "", // initial populated value for this filter
      //     // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
      //     filterFn: this.columnFilterFn, //custom filter function that
      //     // trigger: "enter", //only trigger on enter not on keyup
      //   },
      // })

      this.columns.splice(1, 0, {
        label: "航空業者",
        width: "100px",
        field: "airline",
        filterOptions: {
          styleClass: "class1", // class to be added to the parent th element
          enabled: true, // enable filter for this column
          placeholder: "", // placeholder for filter input
          filterValue: "IT", // initial populated value for this filter
          filterDropdownItems: ["MM", "SL", "VZ", "TR", "D7", "FD", "AK", "IT", "CI", "BR", "JX", "JL", "NH", "UA", "OD"], // dropdown (with selected values) instead of text input
          // filterFn: this.columnFilterFn, //custom filter function that
          // trigger: "enter", //only trigger on enter not on keyup
        },
      })
      // }
      // }
      // }
      let airline = null,
        tag = null

      this.main_title = "OTA 價格整理"
      this.sub_title = '價格單位: 視各業者票種而定，幣值為 JPY 且 <span class="fs-5 text-danger">單程含稅(也包含其他費用)</span> '

      if (sessionStorage) {
        sessionStorage.setItem("page", "LowFareOTA")
        sessionStorage.setItem("ref", false)
        sessionStorage.removeItem("airline", airline)
        sessionStorage.removeItem("tag", tag)
      }

      if (Cookies) {
        Cookies.set("page", "LowFareOTA", { secure: true })
        Cookies.set("ref", false, { secure: true })
        Cookies.remove("airline")
        Cookies.remove("tag")
      }

      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        airline = ref.split("-")[0]
        tag = ref.split("-")[1]

        if (airline === "TEST" && tag === "TT") {
          this.main_title = "測試用"
          this.sub_title = ""

          if (typeof sessionStorage !== "undefined") {
            alert("ok")
          } else {
            alert("not ok")
          }
        }
        this.help_title = "如持有本站會員帳號，可於登入後查看該優惠價格所持有的張數"
        if (sessionStorage) {
          sessionStorage.setItem("ref", true)
          sessionStorage.setItem("airline", airline)
          sessionStorage.setItem("tag", tag)
        }
        if (Cookies) {
          Cookies.set("ref", true, { secure: true })
          Cookies.set("airline", airline, { secure: true })
          Cookies.set("tag", tag, { secure: true })
        }
      }

      if (!sessionStorage.getItem("is_email_confirm")) {
        this.$alert("從 11/8 起一般會員（含驗證會員）於每月可查詢懶人包件數為 5 次，付費會員（或贊助會員）則不受限", "系統訊息", "error").then(() => {
          window.location.href = `${redirect_url}`
        })
      } else {
        this.initLowFare(airline, tag)
      }
    },
    mixins: [GlobalVar],
    data: function () {
      return {
        flag: null,
        main_title: "",
        sub_title: "",
        help_title: "",
        isLoading: false,
        columns: [
          {
            label: "前往官網",
            width: "150px",
            field: "goLink",
            html: true,
          },
          {
            label: "出發日",
            width: "150px",
            field: "departureDate",
            type: "date",
            dateInputFormat: "yyyy-MM-dd",
            dateOutputFormat: "yyyy-MM-dd",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "星期",
            field: "departureDateWeeKFormat",
            width: "130px",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "班次",
            field: "flightNo",
            width: "130px",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          // {
          //   label: "currency",
          //   field: "currency",
          // },
          {
            label: "價格",
            width: "130px",
            field: "fareAmount",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },

          {
            label: "出發地",
            width: "150px",
            field: "departureStation",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "目的地",
            width: "150px",
            field: "arrivalStation",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "資料時間",
            width: "150px",
            field: "fetchCreatedAt",
          },
        ],
        rows: [],
      }
    },
    methods: {
      initLowFare(airline, tag) {
        const me = this
        if (airline == null && tag == null) {
          axios.get(`${me.$apiUrl}lowfareByOTA`).then((response) => {
            me.rows = response.data
          })
        } else {
          let user_uuid = null

          if (sessionStorage) {
            if (sessionStorage.getItem("uuid")) {
              user_uuid = sessionStorage.getItem("uuid")
            }
          }

          if (Cookies) {
            if (Cookies.get("uuid")) {
              user_uuid = Cookies.get("uuid")
            }
          }

          const json_d = {
            airline: airline,
            tag: tag,
            uuid: user_uuid,
          }

          let usage = null

          axios({
            method: "POST",
            url: `${me.$apiUrl}querylowfare_usage2`,
            data: json_d,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
          }).then(function (response) {
            usage = response.data[0]
            // console.log(usage)
            let flag = false
            if (sessionStorage.getItem("role_type") === "Sponsored") {
              flag = true
            } else {
              if (usage.count <= 5 && usage.count >= 0) {
                flag = true
              } else {
                flag = false
              }
            }

            if (flag) {
              axios({
                method: "POST",
                url: `${me.$apiUrl}lowfareByOTA`,
                data: json_d,
                headers: {
                  Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                  "Content-Type": "application/json",
                },
              }).then(function (response) {
                me.rows = response.data
              })
            } else {
              me.$alert("超過每月可查詢件數額度", "系統訊息", "error")
            }
          })
        }
      },
      columnFilterFn: function (data, filterString) {
        const x = parseInt(filterString)
        return data == x
        // return x
        // return data >= x - 5 && data <= x + 5
      },
    },
  }
</script>

<style></style>
